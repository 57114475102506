import React from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import Layout from '../../../../components/layout';
function CheckoutSuccess() {
  return (
    <Layout>
      <Box mt={10}>
        <Box my={5}>
          <Typography align='center' variant='body1' gutterBottom>
            Thank you for registering to attend #GMIS2021, which will be held
          </Typography>
          <Typography align='center' variant='body1' gutterBottom>
            at EXPO's Dubai Exhibition Centre from November 22-27, 2021.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            Your registration is under process.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            Due to COVID-19 capacity restrictions, all registrations need to be verified by the GMIS Organising
            Committee before approval.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            An email confirmation has been sent to you. If you do not see the email in your inbox, please check your
            spam folder. Kindly add our email address to your safe sender list as we will be sending you regular updates
            related to the programme agenda. If you did not receive an email from us, please contact us on{' '}
            {/* <Typography variant='body1' component='span' color='primary'> */}
            <Link href='mailto:registration@gmisummit.com'> registration@gmisummit.com.</Link>
            {/* <a href='tel:registration@gmisummit.com'>registration@gmisummit.com.</a> */}
            {/* <a href='mailto:registration@gmisummit.com.'>registration@gmisummit.com.</a> */}
            {/* </Typography> */}
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
}

export default CheckoutSuccess;
